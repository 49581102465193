<template>

  <el-row class="tac">
    <el-col :span="5">
        <div class="card me-8">
          <div class="card-body">
            <div class="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100" id="#kt_aside_menu" data-kt-menu="true">
              <div class="menu-item">
                  <div class="menu-content pb-2"><span class="menu-section text-dark text-uppercase fs-5 fw-bolder">Constructeurs</span></div>
              </div>
              
              <template  v-for="(item, index) in state.myList" :key="index" >
                <div class='menu-item' v-if="socDispo[item.soc_nom_societe]">
                  
                  <a @click='selectFAQ(item)' :class="{active: state.constructeur==item.soc_nom_societe.toUpperCase()}" class='menu-link'>
                    <span class='menu-title'><span class='bullet bg-primary me-5'></span>{{ item.soc_nom_societe }}</span>
                    <span class="menu-badge" v-if="item.badge > 0">
                      <span class="svg-icon svg-icon-1 svg-icon-success"><inline-svg src="media/icons/duotune/general/gen026.svg" /></span>
                    </span>
                  </a>
                </div>
              </template>
            
            </div>
          </div>
        </div>
    </el-col>
      
    <el-col :span="19">
      <div class="card">
        <div class="card-body">
          <Cisco display='all' v-if="state.constructeur=='CISCO'" />
          <Juniper display='all' v-if="state.constructeur=='JUNIPER'" />
          <Fortinet display='all' v-if="state.constructeur=='FORTINET'" />
          <ExtremeNetworks display='all' v-if="state.constructeur=='EXTREME NETWORKS'" />
          <Polycom v-if="state.constructeur=='POLYCOM'" />
          <Synology v-if="state.constructeur=='SYNOLOGY'"  />

          
        </div>
      </div>
    </el-col>
  </el-row>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";

import Cisco from "@/views/still/contrats/supports/CISCO.vue";
import Juniper from "@/views/still/contrats/supports/Juniper.vue";
import Fortinet from "@/views/still/contrats/supports/FORTINET.vue";
import ExtremeNetworks from "@/views/still/contrats/supports/ExtremeNetworks.vue";
import Polycom from "@/views/still/contrats/supports/Polycom.vue";
import Synology from "@/views/still/contrats/supports/Synology.vue";

export default defineComponent({
  name: "deploy-overview",
  components: {
    Cisco,Fortinet,
    ExtremeNetworks,Polycom,Synology, Juniper
  },
  setup() {
    const state = reactive({
      constructeur: "",
      myList: [] as any,
    });

    const socDispo = {'CISCO': 1, 'JUNIPER': 1, 'FORTINET': 1, 'Extreme Networks': 1, 'Polycom': 1, 'SYNOLOGY':1 }

    onMounted(async () => {
      // ;
      let myList = await getAxios("/getConstructeur");
      state.myList = myList.records;  
      if (state.myList.length > 0) state.constructeur = state.myList[0].soc_nom_societe.toUpperCase();

    });

    const selectFAQ = (con) => {
      state.constructeur = con.soc_nom_societe.toUpperCase();
    }

    return {
      selectFAQ,
      state,
      socDispo
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>

<style>

.menu-link.active {
  font-weight: 700;
  background: #003553 !important;
  color: white !important;
}
.menu-link.active .menu-title {
  color: white !important;
}

</style>