<template>
    <div v-if = "mProps.display=='tabs'">
    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Cisco_logo_blue_2016.svg/langfr-280px-Cisco_logo_blue_2016.svg.png"  style="margin-left:20px; margin-right:60px; float:left; max-width: 140px"/>
    <div class="mb-10" style="margin-left:20px;">
        <div class='fs-5 text-danger'>IMPORTANT</div>
        <span style="word-break: break-word;" class="fs-5">L'ouverture d'un ticket via l'interface Web ou par e-mail le définit automatiquement par défaut sur un ticket de priorité <b>3</b> ou <b>4</b>.<br>Si vous avez un cas de priorité <b>1</b> ou <b>2</b>, vous devez ouvrir le ticket avec le GCC par téléphone.</span>
    </div>
    </div>
    <el-tabs tab-position="left" style="height: 100%" class="demo-tabs" v-if = "mProps.display=='tabs'">
        <el-tab-pane label="Interface Web Cisco">
            <div style="word-break: break-word;" class="fs-1">Via l'interface CISCO</div><br>
            <div style="word-break: break-word;" class="fs-5">Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant : <br><br><a href="https://mycase.cloudapps.cisco.com/case">https://mycase.cloudapps.cisco.com/case</a>.</div>
        </el-tab-pane>
        <el-tab-pane label="Accès par Email">
            <div style="word-break: break-word;" class="fs-1">Via l'envoi d'un Email</div><br>
            <div style="word-break: break-word;" class="fs-5">Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail à : <br><br><a href="mailto:tac@cisco.com. ">tac@cisco.com</a>.</div>                
        </el-tab-pane>
        <el-tab-pane label="Accès par téléphone">
            <div style="word-break: break-word;" class="fs-1">Via un appel téléphonique</div><br>
            <div style="word-break: break-word;" class="fs-5">
                Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.<br>Les numéros actuels sont listés ci-dessous :
                <div class="d-flex flex-column">
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> Asia-Pacific  : <span class="text-primary">&nbsp;&nbsp;+61 2 8448 7107 </span> </li>
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> North America  : <span class="text-primary">&nbsp;&nbsp;1 800 553 2447 </span> </li>
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> EMEA   : <span class="text-primary">&nbsp;&nbsp;+32 2 704 5555 </span> </li>
                </div>
            </div>      
        </el-tab-pane>
    </el-tabs>
    <div v-else>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Cisco_logo_blue_2016.svg/langfr-280px-Cisco_logo_blue_2016.svg.png" class="mb-10" style="float:right; max-width: 200px; margin-right: 10px;  margin-left: 40px;"/>
        <h1>CISCO - Support manager </h1>
        <div class="mt-10 mb-10 bg-light-danger" style='margin-right: 260px; padding: 20px; border-radius: 1em;'>
            <div class='fs-5 text-danger'><b>IMPORTANT</b></div>
            <span class="fs-5">L'ouverture d'un ticket via l'interface Web ou par e-mail le définit automatiquement par défaut sur un ticket de priorité <b>3</b> ou <b>4</b>.<br>Si vous avez un cas de priorité <b>1</b> ou <b>2</b>, vous devez ouvrir le ticket avec le GCC par téléphone.</span>
        </div>

        <div style="word-break: break-word;" class="fs-1 mt-20">Via l'interface CISCO</div><br>
        <div style="word-break: break-word;" class="fs-5">Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant : <br><br><a target="_blank" href="https://mycase.cloudapps.cisco.com/case">https://mycase.cloudapps.cisco.com/case</a>.</div>

        <div style="word-break: break-word;" class="fs-1 mt-20">Via l'envoi d'un Email</div><br>
        <div style="word-break: break-word;" class="fs-5">Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail sur l'adresse <a href="mailto:tac@cisco.com. ">tac@cisco.com</a>.</div>        

        <div style="word-break: break-word;" class="fs-1 mt-20">Via un appel téléphonique</div><br>
        <div style="word-break: break-word;" class="fs-5">
            Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.<br>Les numéros actuels sont listés ci-dessous :
            <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> Asia-Pacific  : <span class="text-primary">&nbsp;&nbsp;+61 2 8448 7107 </span> </li>
                <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> North America  : <span class="text-primary">&nbsp;&nbsp;1 800 553 2447 </span> </li>
                <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> EMEA   : <span class="text-primary">&nbsp;&nbsp;+32 2 704 5555 </span> </li>
            </div>
        </div>  

    </div>

</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "deploy-overview",
  components: {},
    props: {
    display: String,
  },
  setup(props) {

    onMounted(async () => {
      // 
    });
    const mProps = props;
    return { 
        mProps
    };
  },
});
</script>
