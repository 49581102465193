<template>
    <div v-if = "mProps.display=='tabs'">
        <img src="https://www.techmedia.ci/wp-content/uploads/2020/05/Juniper-Networks-Logo-e1588391697173.png"  style="margin-left:20px; margin-right:60px; float:left; max-width: 140px"/>
    </div>
    <el-tabs tab-position="left" style="height: 100%" class="demo-tabs" v-if = "mProps.display=='tabs'">
        <el-tab-pane label="Interface Web Cisco">
            <div style="word-break: break-word;" class="fs-1">Via l'interface CISCO</div><br>
            <div style="word-break: break-word;" class="fs-5">Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant : <br><br><a href="https://mycase.cloudapps.cisco.com/case">https://mycase.cloudapps.cisco.com/case</a>.</div>
        </el-tab-pane>
        <el-tab-pane label="Accès par Email">
            <div style="word-break: break-word;" class="fs-1">Via l'envoi d'un Email</div><br>
            <div style="word-break: break-word;" class="fs-5">Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail à : <br><br><a href="mailto:tac@cisco.com. ">tac@cisco.com</a>.</div>                
        </el-tab-pane>
        <el-tab-pane label="Accès par téléphone">
            <div style="word-break: break-word;" class="fs-1">Via un appel téléphonique</div><br>
            <div style="word-break: break-word;" class="fs-5">
                Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.<br>Les numéros actuels sont listés ci-dessous :
                <div class="d-flex flex-column">
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> Asia-Pacific  : <span class="text-primary">&nbsp;&nbsp;+61 2 8448 7107 </span> </li>
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> North America  : <span class="text-primary">&nbsp;&nbsp;1 800 553 2447 </span> </li>
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> EMEA   : <span class="text-primary">&nbsp;&nbsp;+32 2 704 5555 </span> </li>
                </div>
            </div>      
        </el-tab-pane>
    </el-tabs>
    <div v-else>
        <img src="https://www.techmedia.ci/wp-content/uploads/2020/05/Juniper-Networks-Logo-e1588391697173.png" class="mb-10" style="float:right; max-width: 200px; margin-right: 10px;  margin-left: 40px;"/>
        <h1>JUNIPER - Support manager </h1>
        <div style="word-break: break-word;" class="fs-1 mt-20">Via l'interface JUNIPER</div><br>
        <div style="word-break: break-word;" class="fs-5">
            Pour trouver des informations de support détaillées sur votre produit, rendez-vous sur la page :<br><br><a target="_blank" href="https://support.juniper.net/support/">https://support.juniper.net/support/</a><br><br>ou connectez-vous avec votre compte Juniper sur la page :<br><br><a target="_blank" href="https://iam-signin.juniper.net/">https://iam-signin.juniper.net/</a>.</div>
        <div style="word-break: break-word;" class="fs-1 mt-20">Via le chat Juniper </div><br>
        <div style="word-break: break-word;" class="fs-5">
            Pour l’assistance mondiale, utilisez l'icône Chat en bas à droite de la page :<br><br><a target="_blank" href="https://support.juniper.net/support/requesting-support/">https://support.juniper.net/support/requesting-support/</a><br><br>pour demander une assistance 24 heures sur 24, 7 jours sur 7.
        </div>        

        <div style="word-break: break-word;" class="fs-1 mt-20">Via un appel téléphonique</div><br>
        <div style="word-break: break-word;" class="fs-5">
            Depuis la France, composez le <span class="text-primary">&nbsp;0800 917 669</span><br><br>
            Depuis l’étranger, utilisez un numéro de pays répertorié dans l’un des onglets de la page :<br><br>
            <a target="_blank" href="https://support.juniper.net/support/requesting-support/">https://support.juniper.net/support/requesting-support/</a>
        </div>  

    </div>

</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "deploy-overview",
  components: {},
    props: {
    display: String,
  },
  setup(props) {

    onMounted(async () => {
      // 
    });
    const mProps = props;
    return { 
        mProps
    };
  },
});
</script>
